<template>
    <div class="pagination d-flex flex-stack flex-wrap pt-10" style="width:1200px;margin: 0 auto">
            <div class="d-flex align-items-center">
                <span class="fs-6 fw-bold text-gray-700" style="padding-right:25px">共 {{ totalItems }} 条数据</span>
                <select v-model="pageSize" @change="changePageSize" class="form-select" style="width: 110px;padding-top: 4px;padding-bottom: 4px;">
                    <option v-for="(size,index) in pageSizes" :key="index" :value="size">{{ size }} 条/页</option>
                </select>
            </div>
            <ul class="pagination" style="margin-right: 10px;">
                <li class="page-item" :class="{ disabled: currentPage === 1 }" v-if="currentPage !== 1" style="margin-left: 5px;">
                    <a href="javascript:void(0)" class="page-link" @click="goToFirstPage">首页</a>
                </li>
                <li class="page-item" :class="{ disabled: currentPage === 1 }" v-if="currentPage !== 1" style="margin-left: 5px;">
                    <a class="page-link" @click="previousPage" aria-label="Previous" href="javascript:void(0)">
                        <span aria-hidden="true">&laquo;</span>
                    </a>
                </li>
                <li v-for="pageNumber in displayedPages" :key="pageNumber" class="page-item" style="margin-left: 5px;" :class="{ active: currentPage === pageNumber, disabled: pageNumber === '...' }">
                    <a class="page-link" @click="goToPage(pageNumber)" href="javascript:void(0)">{{ pageNumber }}</a>
                </li>
                <li class="page-item" :class="{ disabled: currentPage === totalPages }" v-if="currentPage !== totalPages" style="margin-left: 5px;">
                    <a class="page-link" @click="nextPage" aria-label="Next" href="javascript:void(0)">
                        <span aria-hidden="true">&raquo;</span>
                    </a>
                </li>
                <li class="page-item" :class="{ disabled: currentPage === totalPages }" v-if="currentPage !== totalPages" style="margin-left: 5px;">
                    <a class="page-link page-link" @click="goToLastPage" href="javascript:void(0)">尾页</a>
                </li>
            </ul>
            <div class="d-flex align-items-center">
                跳转到第 &nbsp;&nbsp;<input type="text" v-model.number="inputPage" style="width: 70px;padding-top: 2px;padding-bottom: 2px;" class="form-control"> &nbsp;&nbsp;页
                <a href="javascript:void(0)" class="btn btn-sm btn-primary" @click="goToPage(inputPage)" style="margin-left: 10px;padding-top: 5px;padding-bottom: 5px;">前往</a>
            </div>
        </div>
</template>
<script>
export default{
    props: {
        totalItems: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            currentPage: 1,
            pageSize: 10,
            pageSizes: [10, 20, 50, 100,300],
            inputPage: 1
        };
    },
    computed: {
        totalPages() {
            return Math.ceil(this.totalItems / this.pageSize);
        },
        displayedPages() {
            const totalPages = this.totalPages;
            if (totalPages <= 5) {
                return Array.from({ length: totalPages }, (_, index) => index + 1);
            } else {
                const start = Math.max(1, this.currentPage - 2);
                const end = Math.min(totalPages, start + 4);
                const pagesArray = [];
                for (let i = start; i <= end; i++) {
                    pagesArray.push(i);
                }
                if (start > 1) {
                    pagesArray.unshift('...');
                }
                if (end < totalPages) {
                    pagesArray.push('...');
                }
                return pagesArray;
            }
        }
    },
    methods: {
        changePageSize() {
            this.currentPage = 1; // 当每页显示的条数改变时，跳转到第一页
            this.$emit('page-change', this.currentPage, this.pageSize);
            this.$emit('page-change-size', this.currentPage, this.pageSize);
        },
        goToPage(pageNumber) {
            if (pageNumber !== this.currentPage && pageNumber > 0 && pageNumber <= this.totalPages) {
                this.currentPage = pageNumber;
                this.$emit('page-change', this.currentPage, this.pageSize);
            }
        },
        previousPage() {
            if (this.currentPage > 1) {
                this.goToPage(this.currentPage - 1);
            }
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.goToPage(this.currentPage + 1);
            }
        },
        goToFirstPage() {
            if (this.currentPage !== 1) {
                this.goToPage(1);
            }
        },
        goToLastPage() {
            if (this.currentPage !== this.totalPages) {
                this.goToPage(this.totalPages);
            }
        }
    }
}
</script>