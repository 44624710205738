// 导入你的自定义组件
import SjInput from "./form/input/index.vue"
import SjTextarea from "./form/textarea/index.vue"
import SjButton from "./button/index.vue"
import SjTable from "./table/index.vue"
import SjTableColumn from "./table/column/index.vue"



const SjComponents = {
    install(Vue) {
        // 表单组件
        Vue.component('SjInput', SjInput);
        Vue.component('SjTextarea', SjTextarea);
        // 按钮组件
        Vue.component('SjButton', SjButton);
        // 列表组件
        Vue.component('SjTable', SjTable);
        Vue.component('SjTableColumn', SjTableColumn);
    }
};

// 导出插件
export default SjComponents;
