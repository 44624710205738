// clipboard.js
import Clipboard from 'clipboard';
import { Message } from 'element-ui';
import Vue from "vue";

export function setupClipboardDirective() {
    Vue.directive('clipboard', {
        bind(el, binding) {
            el.setAttribute('title', '点击复制');
            el.style.cursor = 'pointer';

            const clipboard = new Clipboard(el, {
                text: () => binding.value,
            });

            clipboard.on('success', () => Message.success('复制成功'));
            clipboard.on('error', () => Message.error('复制失败'));

            el.__clipboard__ = clipboard;
        },
        unbind(el) {
            if (el.__clipboard__) {
                el.__clipboard__.destroy();
                delete el.__clipboard__;
            }
        },
    });
}
