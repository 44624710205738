<!-- 组件模板内容 -->
<template>
  <div>
    
    <div class="app-engage" id="kt_app_engage">
      <a
        v-if="typeArr.includes('group')"
        href="javascript:void(0)"
        @click="$router.push({name:'GroupManage'})"
        data-bs-toggle="modal"
        data-bs-target="#kt_app_engage_prebuilts_modal"
        class="app-engage-btn hover-dark"
      >
        <i class="ki-outline ki-abstract-41 fs-1 pt-1 mb-2"></i> 分组管理
      </a>
    </div>
  </div>
</template>

<script>


export default {
  name: "GroupTools",
  // 组件的props定义,用于子组件接收父组件传值
  props: {
    typeArr: {
      type: Array,
    },
  },
  // 组件的data属性
  data() {
    return {
      
    };
  },
  // 组件的方法
  methods: {
   
  },
  // 在created钩子中执行的操作
  created() {},
};
</script>

<!-- 组件的样式 -->
<style scoped>
</style>