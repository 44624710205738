<template>
  <td>
    <slot :row="obj">{{value}}</slot>
  </td>
</template>
<script>
  export default {
    props: {
      value:{
        type: String,
        default: ''
      },
      prop:{
        type: String,
        default: ''
      },
      label:{
        type: String,
        default: ''
      },
      obj:{
        type: Object,
        default: () => {
          return {}
        }
      }
    },
    created() {
    }
  }
</script>
