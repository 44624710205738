<template>
  <input :type="type" class="form-control" :disabled="disabled" :value="value" :placeholder="placeholder" @input="inputChange"/>
</template>
<script>
export default {
  props:{
    value:{
      type:String,
      default:''
    },
    placeholder:{
      type:String,
      default:''
    },
    type:{
      type:String,
      default:'text'
    },
    disabled:{
      type:Boolean,
      default:false
    }
  },
  data(){
    return {
      inputValue:this.value
    }
  },
  watch:{
    value(val){
      this.inputValue = val
    }
  },
  methods:{
    inputChange(e){
      this.$emit('input',e.target.value)
    }
  }
}
</script>
