<template>
  <el-dialog
      title="升级小区数字店"
      :visible.sync="dialogVisible"
      width="30%"
      append-to-body
      :before-close="handleClose">
    <el-form :model="form">
      <el-form-item label="邀请码" label-width="80px">
        <el-input size="medium" v-model="form.inviteCode" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="营业执照"
                    :rules="[
                      { required: true, message: ''},
                    ]"
                    label-width="80px">
        <div style="display: flex">
          <el-upload
              action="#"
              ref="fileBusiness"
              :http-request="requestFileBusiness"
              :list-type="form.businessLicenseUrl===''?'picture-card':'picture'"
              :limit="1"
              :show-file-list="false"
              :auto-upload="true"
          >
            <i class="el-icon-plus" v-if="form.businessLicenseUrl===''"></i>
            <img v-if="form.businessLicenseUrl" :src="form.businessLicenseUrl" alt="" style="width: 148px;height: 148px"/>
          </el-upload>
          <div style="margin-left: 10px">

          </div>
        </div>
      </el-form-item>
      <el-form-item label="会员招募"
                    :rules="[
                      { required: true, message: ''},
                    ]"
                    label-width="80px">
        <div style="display: flex">
          <el-upload
              action="#"
              ref="fileVip"
              :http-request="requestFileVip"
              :list-type="form.fileUrl===''?'picture-card':'picture'"
              :limit="1"
              :show-file-list="false"
              :auto-upload="true"
          >
            <i class="el-icon-plus" v-if="form.fileUrl===''"></i>
            <img v-if="form.fileUrl" :src="form.fileUrl" alt="" style="width: 148px;height: 148px"/>
          </el-upload>
          <div style="margin-left: 10px;font-size: 12px;color: #999">
            小区数字店会员招募300位以上（含私域会员）
          </div>
        </div>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import {uploadFileApi} from "@/api/uploadFileApi";
import {submitDigitalApi} from "@/api/lxh/digital";

export default {
  name: "TeamLeaderUpgrade",
  data() {
    return {
      dialogVisible: false,
      form: {
        inviteCode: "",
        businessLicenseUrl:"",
        fileUrl:"",
      },
      fileBusiness:"",
      fileVip:""
    };
  },
  methods: {
    async save(){
      await submitDigitalApi(this.form)
      this.dialogVisible = false;
      this.$emit("close")
    },
    async requestFileBusiness({file}){
      this.$refs.fileBusiness.clearFiles()
      var formData = new FormData();
      formData.append("file", file);
      formData.append("moduleName", "business");
      let {data}=await uploadFileApi(formData)
      this.form.businessLicenseUrl = data.fileUrl
    },
    async requestFileVip({file}){
      this.$refs.fileVip.clearFiles()
      var formData = new FormData();
      formData.append("file", file);
      formData.append("moduleName", "business");
      let {data}=await uploadFileApi(formData)
      this.form.fileUrl = data.fileUrl
    },
    init() {
      this.form={
        inviteCode: "",
        businessLicenseUrl:"",
        fileUrl:"",
      }
      this.dialogVisible = true
    },
    handleClose(done) {
      done();
    }
  }
};
</script>
