<template>
  <div id="kt_app_header" class="app-header d-flex flex-column flex-stack" style="background: #fff">
    <div class="d-flex flex-stack flex-grow-1">
      <div class="app-header-logo d-flex align-items-center ps-lg-12" id="kt_app_header_logo">
        <!--折叠按钮-->
        <div id="kt_app_sidebar_toggle"
             @click="toggleSidebar"
             class="app-sidebar-toggle btn btn-sm btn-icon bg-body btn-color-gray-500 btn-active-color-primary w-30px h-30px ms-n2 me-4 d-none d-lg-flex"
             data-kt-toggle="true" data-kt-toggle-state="active" data-kt-toggle-target="body"
             data-kt-toggle-name="app-sidebar-minimize">
          <i class="ki-outline ki-abstract-14 fs-3 mt-1"></i>
        </div>
        <div class="btn btn-icon btn-active-color-primary w-35px h-35px ms-3 me-2 d-flex d-lg-none"
             id="kt_app_sidebar_mobile_toggle">
          <i class="ki-outline ki-abstract-14 fs-2"></i>
        </div>
        <!-- 网站Logo -->
        <a href="javascript:void(0)" class="app-sidebar-logo">
          <img alt="Logo" class="h-25px theme-light-show" :src="obj.headerLogoUrl"/>
        </a>
      </div>
      <div class="app-navbar flex-grow-1 justify-content-end" id="kt_app_header_navbar">
        <div class="app-navbar-item d-flex align-items-stretch flex-lg-grow-1"
             style="display: flex; align-items: center!important;">
          <div id="kt_header_search" class="header-search d-flex align-items-center w-lg-350px"
               data-kt-search-keypress="true" data-kt-search-min-length="2" data-kt-search-enter="enter"
               data-kt-search-layout="menu" data-kt-search-responsive="true" data-kt-menu-trigger="auto"
               data-kt-menu-permanent="true" data-kt-menu-placement="bottom-start">
            <div data-kt-search-element="toggle" class="search-toggle-mobile d-flex d-lg-none align-items-center">
              <div class="d-flex">
                <i class="ki-outline ki-devices fs-1 fs-1"></i>
              </div>
            </div>
            <form data-kt-search-element="form" class="d-none d-lg-block w-100 position-relative mb-5 mb-lg-0"
                  autocomplete="off">
              <i class="ki-outline ki-color-swatch devices-icon fs-2 text-gray-500 position-absolute top-50 translate-middle-y ms-5"></i>
              <input @keyup.enter="verifyComboOrder" v-model="orderNo" type="text"
                     class="search-input form-control form-control  h-lg-40px ps-13"
                     style="border-radius:  0.75rem 0 0 0.75rem;border-color: rgb( 27,132,255)" value=""
                     placeholder="请在此输入券码或用扫码枪扫描券码" data-kt-search-element="input"/>
            </form>
          </div>
          <div style="display: flex;justify-items: center !important;">
            <button class="btn btn-primary h-lg-40px" style="border-radius: 0 0.75rem 0.75rem 0;width: 93px"
                    @click="verifyComboOrder">验&nbsp;券
            </button>
          </div>
          <!--end::Search-->
        </div>
        <div style="display: flex;align-items: center;"
             v-if="permission.includes('store:digital:upgrade') && isUpgrade">
          <el-button size="mini" type="primary" round @click="upgradeDigital">升级</el-button>
        </div>
        <UserBar></UserBar>
        <!--退出登录-->
        <div class="app-navbar-item ms-2 ms-lg-6 me-lg-6">
          <a href="javascript:void(0)"
             @click="loginOut"
             class="btn btn-icon btn-custom btn-color-gray-600 btn-active-color-primary w-35px h-35px w-md-40px h-md-40px">
            <i class="ki-outline ki-exit-right fs-1"></i>
          </a>
        </div>
        <div class="app-navbar-item ms-2 ms-lg-6 ms-n2 me-3 d-flex d-lg-none">
          <div
              class="btn btn-icon btn-custom btn-color-gray-600 btn-active-color-primary w-35px h-35px w-md-40px h-md-40px"
              id="kt_app_aside_mobile_toggle">
            <i class="ki-outline ki-burger-menu-2 fs-2"></i>
          </div>
        </div>
      </div>
    </div>
    <TeamLeaderUpgrade ref="TeamLeaderUpgrade" @close="checkUpgradeDigital"></TeamLeaderUpgrade>
    <div class="app-header-separator" style="margin-right: 80px;background: #F9F9F9"></div>
  </div>
</template>
<script>
import UserBar from "../user-bar/index.vue"
import {offlineOrderVerifyApi} from "@/api/orderApi";
import {mapState, mapGetters} from "vuex";
import {checkUpgradeDigitalApi} from "@/api/userApi";
import TeamLeaderUpgrade from "@/layout/components/team-leader-upgrade/index.vue";
import {submitDigitalApi} from "@/api/lxh/digital";

export default {
  components: {UserBar,TeamLeaderUpgrade},
  computed: {
    ...mapState("web", ["obj"]),
    ...mapGetters(["permission"])
  },
  data() {
    return {
      orderNo: '',
      isUpgrade: true,
      upgradeObj: {
        digitalLevel: -1,
        digitalLevelName: "团长升级条件",
        isUpgrade: true
      }
    }
  },
  created() {
    if (this.permission.includes("store:digital:upgrade")) {
      this.checkUpgradeDigital()
    }
  },
  methods: {
    async checkUpgradeDigital() {
      let {data} = await checkUpgradeDigitalApi();
      this.isUpgrade = data.isUpgrade
      this.upgradeObj = data
    },
    upgradeDigital() {
      switch (this.upgradeObj.digitalLevel) {
        case -1:
          this.$refs.TeamLeaderUpgrade.init()
          break;
        default:
          this.$confirm(`您确定升级，${this.upgradeObj.digitalLevelName}?`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(async () => {
            await submitDigitalApi({

            })
            this.$message({
              type: 'success',
              message: '已经提交审核!'
            });
            this.checkUpgradeDigital()
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消'
            });
          });
          return;
      }
    },
    toggleSidebar() {
      this.$emit("toggleSidebar")
    },
    loginOut() {
      this.$store.dispatch("user/loginOut").then(() => {
        this.$router.push(`/login?redirect=${this.$route.fullPath}`)
      });
    },
    async verifyComboOrder() {
      let orderNo = this.orderNo.trim()
      if (orderNo === '') {
        this.$message.error("请输入券码")
        return
      }
      let res = await offlineOrderVerifyApi({orderNo})
      if (res.code === 200) {
        this.$message.success("核销成功")
        this.orderNo = ''
      } else {
        this.$message.error(res.msg)
      }
    }
  }
}
</script>
