<template>
  <div>

    <el-dialog
        id="dialog11123123123"
        title="提示"
        :visible.sync="dialogVisible"
        width="20%"
        top="10px"
    >
      <iframe id="dialog11123123123" :src="url" width="100%" style="aspect-ratio:430/932 "></iframe>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "MobileModalBox",
  data() {
    return {
      dialogVisible: false,
      url: ""
    }
  },
  methods: {
    open(url) {
      this.dialogVisible = true
      this.url = url;

      this.$emit("open-event")
    }
  }
}

</script>

<style>
#dialog11123123123 .el-dialog__header {
  display: none;
}

#dialog11123123123 .el-dialog__body {
  padding: 0;
}
</style>