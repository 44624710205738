<template>
  <div
  >
    <el-dialog
      title="支付"
      :visible.sync="payType"
      width="30%"
      append-to-body
    >
      <div>
        请选择支付方式:
      </div>
      <div style="display: flex;margin-top: 10px">
        <div style="border: 1px solid rgb(226,226,226);padding: 10px;width: 48%;margin-right: 10px">
          <el-radio v-model="type" label="zfb"><img src="./../../assets/img/alipay.png" height="40px"></el-radio>
        </div>
        <div style="border: 1px solid rgb(226,226,226);padding: 10px;width: 48%;margin-right: 10px">
          <el-radio v-model="type" label="wx" disabled ><img src="./../../assets/img/wxpay.png" height="40px"></el-radio>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="pay">支 付</el-button>
        </span>
    </el-dialog>
    <el-dialog
        title="支付(支付成功后请勿刷新页面)"
        :visible.sync="payModal"
        width="80%"
        top="10"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        append-to-body
        @close="payCloseDialog">
     <div
         v-loading="loading"
         element-loading-text="拉取支付中..."
         element-loading-spinner="el-icon-loading"
         element-loading-background="rgba(0, 0, 0, 0.8)"
     >
       <iframe :srcdoc="qianHtml"
               @load="handleIframeLoad"
               frameborder="no"
               border="0"
               marginwidth="0"
               marginheight="0"
               scrolling="no"
               width="100%"
               height="800"
               ref="zfbPay"
               style="overflow:hidden;">
       </iframe>
     </div>
<!--      <span slot="footer" class="dialog-footer">-->
<!--        <el-button type="primary" @click="closeModal">关 闭</el-button>-->
<!--      </span>-->
    </el-dialog>

  </div>
</template>
<script>
import {vipPayApi} from "@/api/vipUserApi";
import {mapMutations, mapState} from "vuex";

export default {
  name : "VipPayComponent",
  data(){
    return{
      loading:false,
      qianHtml:"",
      payType:false,
      type:"zfb",
      item:{}
    }
  },
  computed: {
    ...mapState('diaLog',['payModal'])
  },
  methods:{
    ...mapMutations('diaLog',['openModal', 'closeModal']),
    handleIframeLoad(){
      const iframe = this.$refs.zfbPay;
      if (iframe && iframe.contentWindow) {
        const iframeDocument = iframe.contentWindow.document;
        if (iframeDocument.body.innerHTML.indexOf("支付成功正在跳转...")>10){
          this.$message({
            message: '支付成功！',
            type: 'success'
          });

          localStorage.removeItem("paying")
          if (localStorage.getItem("payUrl") === "goods") {
            localStorage.removeItem("payUrl")
            this.closeModal();
            this.$router.push({path: "/goods"})
          } else {
            location.reload();
          }
        }
      }
    },
    payCloseDialog(){
      this.$emit("checkPay")
    },
    pay(){
      localStorage.setItem("paying","true")
      if (this.type==='zfb'){
        this.zfbPay();
      }else{
        this.wxPay();
      }
    },
    async zfbPay(){
      this.payType=false;
      this.loading=true
      this.openModal();
      let {data}=await vipPayApi(this.item)
      this.qianHtml=data
      setTimeout(()=>{
        this.loading=false;

      },2000)
    },
    async wxPay(){
      this.loading=true
      this.payType=false;
      this.openModal();
      let {data}=await vipPayApi(this.item)
      this.qianHtml=data
      setTimeout(()=>{
        this.loading=false;
      },2000)
    },
    async openPay(item){
      this.payType=true
      this.item=item
    }
  },
  created() {

  },
  mounted() {

  }
}
</script>
