import service from "@/utils/request"
// 获取全国商品池列表
export function getOrderTwoListApi(data) {
    return service({
        url: '/orderTwo/getOrderTwoList',
        method: 'get',
        params:data
    })
}

export function getOrderSjListApi(data) {
    return service({
        url: '/order/sj/getOrderList',
        method: 'get',
        params:data
    })
}

export function getOrderSjListFlowApi(data) {
    return service({
        url: '/order/sj/getOrderListFlow',
        method: 'get',
        params:data
    })
}
export function getOrderListCountApi(data) {
    return service({
        url: '/order/sj/getListCount',
        method: 'get',
        params:data
    })
}
export function updateOrderCancelApi(data) {
    return service({
        url: '/order/sj/updateOrderCancel',
        method: 'put',
        params:data
    })
}
export function exportOrderApi(data) {
    return service({
        url: '/order/sj/exportOrder',
        method: 'get',
        params:data,
        responseType: 'blob', 
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
}
export function getOrderInfoApi(data) {
    return service({
        url: '/order/sj/getOrderInfo',
        method: 'get',
        params:data
    })
}
export function updateOrderDeliveryApi(data) {
    return service({
        url: '/order/sj/updateOrderDelivery',
        method: 'put',
        data
    })
}
export function getMyCashOutOrdersApi(data) {
    return service({
        url: '/order/sj/getMyCashOutOrders',
        method: 'get',
        params:data,
    })
}
// 快递 
export function getExpressCodeListApi(data) {
    return service({
        url: '/expresscode/getExpressCodeList',
        method: 'get',
        params:data,
    })
}
export function updateBatchOrderDeliveryApi(data) {
    return service({
        url: '/order/sj/updateBatchOrderDelivery',
        method: 'post',
        data,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
}
// updateBatchOrderDelivery

export function orderPaymentApi(data) {
    return service({
        url: '/order/sj/orderPayment',
        method: 'put',
        params:data,
    })
}

// 根据订单号查询是否有同一个购物车下的订单
export function checkCartOrderApi(data) {
    return service({
        url: '/order/sj/checkCartOrder',
        method: 'post',
        data,
    })
}

// /order/sj/batchOrderPayment
// 批量付款
export function batchOrderPaymentApi(data) {
    return service({
        url: '/order/sj/batchOrderPayment',
        method: 'put',
        data
    })
}

// /order/sj/updateOrderInvoiceState
export function updateOrderInvoiceStateApi(data) {
    return service({
        url: '/order/sj/updateOrderInvoiceState',
        method: 'put',
        data
    })
}
//获取七日订单
// /order/sj/getDayOrderCount
export function getDayOrderCountApi() {
    return service({
        url: '/order/sj/getDayOrderCount',
        method: 'get',
    })
}
// 获取月订单
// /order/sj/getMonthOrderCount
export function getMonthOrderCountApi() {
    return service({
        url: '/order/sj/getMonthOrderCount',
        method: 'get',
    })
}

// 查询总销售额
export function getTotalSalesRevenueApi() {
    return service({
        url: '/order/sj/getTotalSalesRevenue',
        method: 'get',
    })
}

// 查询今日销售额
export function getTotalSalesRevenueDayApi() {
    return service({
        url: '/order/sj/getTotalSalesRevenueDay',
        method: 'get',
    })
}

// 查询30日销售额
export function getThirtyDaySalesRevenueApi() {
    return service({
        url: '/order/sj/getThirtyDaySalesRevenue',
        method: 'get',
    })
}

export function offlineOrderVerifyApi(data){
    return service({
        url: '/offline/order/verifyOrder',
        method: 'post',
        data
    })
}


export function offlineOrderCancelApi(data){
    return service({
        url: '/offline/order/cancel',
        method: 'post',
        data
    })
}