const getters = {
    token: state => state.user.token,
    roles: state => state.user.roles,
    permission_routes: state => state.permission.routes,
    permission: state=>state.user.permission,
    digitalBindCode:state=>state.user.digitalBindCode,
    digitalLevel:state=>state.user.digitalLevel
  }

  export default getters
