<template>
  <a href="javascript:void(0)" class="btn" :class="`${btnType} ${btnLight} ${btnOutline} ${btnActive} ${btnActiveLight} ${btnDisabled}`" @click="click">
    <i class="fas fs-4 me-2" v-if="icon" :class="icon"></i>
    <slot></slot>
  </a>
</template>
<script>
export default {
  name: "SjButton",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "",
      validator(value) {
        // 预定义的允许值
        const validStatuses = ['light', 'primary', 'secondary', 'success', 'info', 'warning', 'warning','danger','dark',''];
        // 检查传入的值是否在预定义值中
        return validStatuses.includes(value);
      }
    },
    light:{
      type: String,
      default: "",
      validator(value) {
        // 预定义的允许值
        const validStatuses = ['light', 'primary', 'secondary', 'success', 'info', 'warning', 'warning','danger','dark',''];
        // 检查传入的值是否在预定义值中
        return validStatuses.includes(value);
      }
    },
    outline:{
      type: String,
      default: "",
      validator(value) {
        // 预定义的允许值
        const validStatuses = ['light', 'primary', 'secondary', 'success', 'info', 'warning', 'warning','danger','dark',''];
        // 检查传入的值是否在预定义值中
        return validStatuses.includes(value);
      }
    },
    active:{
      type: String,
      default: "",
      validator(value) {
        // 预定义的允许值
        const validStatuses = ['light', 'primary', 'secondary', 'success', 'info', 'warning', 'warning','danger','dark',''];
        // 检查传入的值是否在预定义值中
        return validStatuses.includes(value);
      }
    },
    activeLight:{
      type: String,
      default: "",
      validator(value) {
        // 预定义的允许值
        const validStatuses = ['light', 'primary', 'secondary', 'success', 'info', 'warning', 'warning','danger','dark',''];
        // 检查传入的值是否在预定义值中
        return validStatuses.includes(value);
      }
    },
    icon:{
      type: String,
      default: ""
    },

  },
  computed:{
    btnDisabled(){
      if (this.disabled){
        return 'disabled';
      }else{
        return ""
      }
    },
    btnType() {
      if (this.type){
        return 'btn-'+this.type;
      }else{
        return ""
      }
    },
    btnLight() {
      if (this.light){
        return 'btn-light-'+this.light;
      }else{
        return ""
      }
    },
    btnOutline() {
      if (this.outline){
        return 'btn-outline btn-outline-dashed btn-outline-'+this.outline;
      }else{
        return ""
      }
    },
    btnActive() {
      if (this.active){
        return 'btn-active-'+this.active;
      }else{
        return ""
      }
    },
    btnActiveLight(){
      if (this.activeLight){
        return 'btn-active-light-'+this.activeLight;
      }else{
        return ""
      }
    },
  },
  methods:{
    click(){
      if (!this.disabled){
        this.$emit('click')
      }
    }
  }
}
</script>
