<template>
  <div class="table-responsive">
    <table class="table" :class="`${tableBorder} ${tableRowBorder} ${tableStriped}`">
      <thead>
        <tr class="fw-bold fs-6 text-gray-800">
          <th v-for="(item,index) in tableColumns" :key="index">{{item.label}}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item,index) in data" :key="index">
            <table-column v-for="(column, colIndex) in tableColumns" :key="colIndex" :value="item[column.prop]" :obj="item">
                {{item[column.prop]}}
            </table-column>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import tableColumn from "./column1/index.vue";


export default {
  components: {
    tableColumn
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    border: {
      type: Boolean,
      default:false
    },
    rowBorder: {
      type: Boolean,
      default:false
    },
    striped:{
      type: Boolean,
      default:false
    }
  },
  data() {
    return {
      tableColumns: [

      ]
    }
  },

  created() {
    this.initTable()
    console.log(this.$slots)
  },
  methods: {
    initTable(){
      this.$slots.default.forEach(e=>{
        if(e.componentOptions){
          this.tableColumns.push(e.componentOptions.propsData)
        }
      })
    }
  },
  computed:{
    /**
     * 全边框
     * @returns {string}
     */
    tableBorder(){
      return this.border ? "table-bordered" : ""
    },
    /**
     * 横向边框
     * @returns {string}
     */
    tableRowBorder(){
      return this.border ? "table-row-dashed" : ""
    },
    /**
     * 斑马线
     * @returns {string}
     */
    tableStriped(){
      return this.striped ? "table-striped" : ""
    }
  }
}
</script>
