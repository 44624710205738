import defaultSettings from '@/settings'

const title = defaultSettings.title || '向阳岁月-商家端'

export default function getPageTitle(pageTitle) {
  if (pageTitle) {
    return `${title}-${pageTitle}`
  }
  return `${title}`
}
