
export const material=[
    {
        value: 'store',
        label: '门店管理'
    }, {
        value: 'good',
        label: '商品管理'
    },{
        value: 'combo',
        label: '套餐管理'
    }
]
