import service from "@/utils/request"

/**
 * 协会获取验证码
 * @param params
 * @returns {*}
 */
export function getCodeApi(params) {
    return service({
        url: '/vipUser/getCode',
        method: 'get',
        params
    })
}

/**
 * 协会注册
 *
 * @param data
 * @returns {*}
 */
export function registerVipApi(data) {
    return service({
        url: '/vipUser/registerVip',
        method: 'post',
        data
    })
}

/**
 * 协会支付
 */
export function vipPayApi(data) {
    return service({
        url: '/vip_increment/submit',
        method: 'post',
        data
    })
}

/**
 * 商会判断支付
 */
export function vipCheckPayApi(params) {
    return service({
        url: '/vip_increment/checkIsComplete',
        method: 'get',
        params
    })
}
