<template>
  <el-dialog
      title="商家相册"
      :visible.sync="materialDialog"
      width="60%"
      append-to-body
      top="30px"
      class="material_body"
      :before-close="handleClose">
    <el-dialog
        title="裁切封面"
        :visible.sync="cropperModel"
        width="950px"
        append-to-body
        center
    >
      <cropper-image
          :Name="cropperName"
          @uploadImgSuccess="handleUploadSuccess"
          ref="cropperImage">
      </cropper-image>
    </el-dialog>
    <div style="display: flex;justify-content: space-between">
      <el-tabs v-model="activeName">
        <el-tab-pane label="我的图库" name="first"></el-tab-pane>
      </el-tabs>
      <div style="display: flex">
        <div style="margin-right: 10px">
          <el-button type="danger" size="mini" v-if="!isEdit" @click="isEdit = true">删除图片</el-button>
          <el-button type="danger" size="mini" v-else @click="isEdit = false">完成</el-button>
        </div>
        <div>
          <el-upload
              :show-file-list="false"
              :http-request="uploadFile"
              action=""
          >
            <el-button type="success" size="mini">上传图片</el-button>
          </el-upload>
        </div>
      </div>

    </div>
    <el-row :gutter="30">
      <el-col :span="4">
        <div class="material_box">
          <div v-for="(item,index) in materialList" :key="index">
            <div @click="materialTypeChange(item.value,index)" :class="{
            'materialHover':typeIndex===index
          }" class="material">
              <div>
                {{ item.label }}
              </div>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="20">
        <div style="min-height: 460px;display: flex;flex-direction: column;justify-content: space-between;">
          <div>
            <div style="font-weight: bold; font-size: 16px;">
              图片列表
            </div>
            <div style="display: flex;flex-wrap: wrap;margin-top: 10px">
              <div v-for="(item,index) in rows" :key="index"
                   style="border-radius: 5px;height: 112px;overflow: hidden;width: 200px;margin-bottom: 20px;position: relative;margin-right: 10px">
                <div>
                  <img :src="item.fullUrl" width="200px" v-if="!checkIfVideo(item.fullUrl)"/>
                  <video :src="item.fullUrl" controls width="200px" v-else/>
                </div>
                <div v-if="!isEdit"
                    style="text-align: center;position: absolute;top: 5px;right: 5px;width: 20px;height: 20px;border: 1px solid #e0e0e0;background: #fff;border-radius: 1px;cursor: pointer"
                    :class="{
               selectedBtn:selIndex.includes(item.id)
             }" @click="selectedImg(item)">
                  <i class="el-icon-check" v-if="selIndex.includes(item.id)"></i>
                </div>
                <div v-else
                     @click="deleteMaterial(item.id)"
                     style="text-align: center;position: absolute;top: 5px;right: 5px;padding: 0 5px;color: red;font-weight:bold;cursor:pointer;text-decoration: underline;">
                  删除
                </div>
                <div
                    style="position: absolute;top: 0;left: 0;border-radius: 0 0 5px 0;background: #fff;color: #000;padding: 0 5px;"
                    v-if="checkIfVideo(item.fullUrl)">
                  视频
                </div>
                <div
                    style="position: absolute;top: 0;left: 0;border-radius: 0 0 5px 0;background: #fff;color: #000;padding: 0 5px;"
                    v-else>
                  图片
                </div>
              </div>
            </div>
          </div>
          <div style="display: flex;justify-content: right;">
            <paginations :layout="'total, prev, pager, next'" :pageSizes="[12,24,36]" v-show="total>0" :total="total"
                         :page.sync="params.current" :limit.sync="params.size"
                         @pagination="getList"
            />
          </div>
        </div>
        <div class="separator separator-dashed" style="padding-top: 0;margin-top: 0"></div>
        <div class="my-4">
          <div style="font-weight: bold;font-size: 16px">已选图片 <span
              style="font-size: .95rem!important;color: #99A1B7">(请选择{{ size }}张图片)</span></div>
          <div style="display: flex;flex-wrap: wrap;min-height: 160px;margin-top: 10px">
            <div v-for="(item,index) in selImg" :key="index"
                 style="border-radius: 5px;height: 110px;overflow: hidden;width: 180px;margin-bottom: 20px;position: relative;margin-right: 10px">
              <div>
                <img :src="item.fullUrl" width="180px" v-if="!checkIfVideo(item.fullUrl)" style=""/>
                <video :src="item.fullUrl" controls width="180px" v-else/>
              </div>
              <div class="closeHover"
                   style="line-height: 20px;border: 1px solid #e0e0e0;border-radius: 50%;text-align: center;position: absolute;top: 5px;right: 5px;width: 20px;height: 20px;background: #fff;cursor: pointer"
                   @click="selectedImg(item)">
                <i class="el-icon-close"></i>
              </div>
            </div>
          </div>
        </div>
        <div style="display: flex;justify-content: right">
          <el-button size="mini" @click="materialDialog = false">取 消</el-button>
          <el-button type="primary" size="mini" @click="useImg">直接使用 ({{ selImg.length }}/{{ size }})</el-button>
        </div>
      </el-col>
    </el-row>
  </el-dialog>

</template>
<script>
import {mixins} from "@/components/MaterialComponent/mixins";
import CropperImage from "@/components/CropperImage/index.vue";

export default {
  components: {CropperImage},
  mixins: [mixins],
  data() {
    return {
      formValidate: {
        mainImage: '',
      },
      ruleValidate: {
        mainImage: [
          {required: true, message: '请上传封面', trigger: 'blur'}
        ],
      },
      //裁切图片参数
      cropperModel: false,
      cropperName: '',
      imgName: '',
      imgVisible: false
    }
  },
  methods: {
    //图片上传成功后

  }
}
</script>
<style>
.closeHover:hover {
  background: red !important;
  color: #fff;
}

.materialHover {
  background: #009EF7 !important;
  color: #fff;
  border-left: 2px solid #63B0FF !important;
}

.material_box {
  border-top: 1px solid #eee;
  border-right: 1px solid #eee;
  border-bottom: 1px solid #eee;
  border-left: 2px solid #fff;
  border-radius: 3px;
  font-weight: bold;
}

.material {
  background: #fff;
  cursor: pointer;
  padding: 10px 10px 10px 20px;
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
}

.selectedBtn {
  background: #009EF7 !important;
  border: none;
  color: #fff;
}

.material_body {
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>

