// CopyTextDirective.js
import Vue from 'vue';
import VueClipboard from 'vue-clipboard2';

Vue.directive('copy-text', {
    bind(el, binding) {
        el.addEventListener('click', () => {
            VueClipboard.copy(binding.value).then(() => {
                console.log('Text copied: ' + binding.value);
                // 在这里你可以根据需求进行提示等操作
            }).catch(() => {
                console.error('Failed to copy text');
                // 在这里你可以根据需求进行提示等操作
            });
        });
    }
});
