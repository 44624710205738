<!-- 组件模板内容 -->
<template>
  <div>
    <div
      :class="classObj"
      :id="id"
      tabindex="-1"
      aria-hidden="true"
      style="z-index: 500;"
      :style="{ display: show ? 'block' : 'none' }"
      role="dialog"
    >
      <div
        class="modal-dialog modal-dialog-centered w-60"
        :style="'max-width: ' + width + ' !important;'"
      >
        <div class="modal-content">
          <div class="modal-header" style="position: relative">
            <h2>{{ title }}</h2>
              <div style="position: absolute;right: 70px;">
                <slot name="header-buttons"></slot>
              </div>

            <div
              class="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
            >

              <span class="svg-icon svg-icon-1" v-if="closeBtn">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  @click="close"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g
                    transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)"
                    fill="#000000"
                  >
                    <rect
                      fill="#000000"
                      x="0"
                      y="7"
                      width="16"
                      height="2"
                      rx="1"
                    />
                    <rect
                      fill="#000000"
                      opacity="0.5"
                      transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)"
                      x="0"
                      y="7"
                      width="16"
                      height="2"
                      rx="1"
                    />
                  </g>
                </svg>
              </span>
            </div>
          </div>
          <div class="modal-body py-lg-10 px-lg-10">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {boolean} from "yup";

export default {
  name: "",
  // 组件的props定义,用于子组件接收父组件传值
  props: {
    title: {
      type: String,
      required: false,
      default: "model 名称",
    },
    closeBtn:{
      type: Boolean,
      default: true
    },
    width: {
      type: String,
    },
    id: {
      type: String,
      required: false,
      default: "modal111",
    },
  },
  // 组件的data属性
  data() {
    return {
      classObj: {
        modal: true,
        fade: true,
        show: true,
      },
      show: false,
    };
  },
  // 组件的方法
  methods: {
    close() {
      this.show = false;
      this.classObj.show = false;
      this.$emit("close-change", false);
    },
    open() {
      this.show = true;
      this.classObj.show = true;
      this.$emit("open-change");
    },
    handleKeyDown(event) {
      if (event.key === 'Escape' || event.key === 'Esc' || event.keyCode === 27) {
        this.close()
      }
    },
    handleDocumentClick(event) {
      
    },
  },
  // 在created钩子中执行的操作
  created() {},
  mounted(){
    window.addEventListener('keydown', this.handleKeyDown);
    document.addEventListener('click', this.handleDocumentClick);
  },
  beforeDestroy(){
    window.removeEventListener('keydown', this.handleKeyDown);
    document.removeEventListener('click', this.handleDocumentClick);
  }
};
</script>

<!-- 组件的样式 -->
<style scoped>
.modal.show {
  background-color: rgba(0, 0, 0, 0.5); /* 浅0灰色 */
}
</style>