import {deleteMaterialApi, queryMaterialPageApi, uploadMaterialApi} from "@/api/lxh/material";
import {material} from "@/utils/materialType";


export const mixins = {
    props:{

    },
    data(){
        return{
            isEdit:false,
            size:1,
            materialDialog:false,
            params:{
                current:1,
                groupTag:"",
                size:20,
                fileName:""
            },
            total:0,
            rows:[],
            materialList:[],
            activeName:"first",
            typeIndex:0,
            fileType:'', //'' 不限； video 视频； img 图片；
            selIndex:[],
            selImg:[]
        }
    },

    methods:{
        deleteMaterial(id){
            this.$confirm('确认删除该素材吗？请确保该素材没有被使用', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
               let data =await deleteMaterialApi({id:id})
                if (data.data){
                    this.$message({
                        type:'success',
                        message: '删除成功!'
                    });
                    await this.getList()
                }else {
                    this.$message({
                        type:'error',
                        message: '删除失败!'
                    });
                }
            }).catch(() => {
            });
        },
        async handleUploadSuccess(data) {
            const file = new File([data], 'image.jpeg', { type: 'image/jpeg', lastModified: Date.now() });
            let formData = new FormData();
            formData.append("groupTag",this.params.groupTag)
            formData.append("file",file)
            await uploadMaterialApi(formData)
            this.cropperModel=false;
            await this.getList()
        },
        /**
         * 上传图片
         */
        async uploadFile(e){
            if (this.checkIfImg(e.file.name)) {
                this.cropperModel=true;
                this.$nextTick(()=>{
                    this.$refs.cropperImage.setFileImg(e);
                })
            }else{
                let formData = new FormData();
                formData.append("groupTag",this.params.groupTag)
                formData.append("file",e.file)
                await uploadMaterialApi(formData)
                await this.getList()
            }

        },
        checkIfVideo(url) {
            const videoExtensions = /\.(mp4|avi|mov|wmv|flv|webm)$/i;
            return videoExtensions.test(url);
        },
        checkIfImg(url) {
            const videoExtensions = /\.(jpg|jpeg|png|webp|bmp|gif|svg)$/i;
            return videoExtensions.test(url);
        },
        /**
         * 选择图片
         * @param item
         */
        selectedImg(item){
            if (this.fileType === 'img' && this.checkIfVideo(item.fullUrl)){
                this.$message.error("无法选择视频")
                return
            }
            if (this.fileType === 'video' && this.checkIfImg(item.fullUrl)){
                this.$message.error("无法选择图片")
                return
            }
            if (this.selIndex.includes(item.id)){
                this.selIndex.splice(this.selIndex.indexOf(item.id),1)
                this.selImg=this.selImg.filter((ite)=> ite.id!==item.id)
            }else{
                if (this.selIndex.length>=this.size){
                    this.$message.error("最多选择"+this.size+"张图片")
                    return
                }
                this.selIndex.push(item.id)
                this.selImg.push(item)
            }
        },
        /**
         * 类型切换
         * @param val
         * @param index
         */
        materialTypeChange(val,index) {
            this.params.groupTag=val
            this.typeIndex=index
            this.getList()
        },
        /**
         * 回调数据
         */
        useImg(){
          this.materialDialog=false;
          this.$emit("useImg",this.selImg);
        },
        /**
         * 关闭弹窗
         * @param done
         */
        handleClose(done){
            done()
        },
        /**
         * 初始化
         * @param size
         */
        init(size,fileType){
            this.selIndex=[];
            this.selImg=[];
            this.materialDialog=true
            this.materialList=material;
            this.fileType = fileType;
            this.size=size;
            this.params.groupTag=this.materialList[0].value
            this.getList()
        },
        /**
         * 获取列表
         * @returns {Promise<void>}
         */
        async getList(){
            let {data}=await queryMaterialPageApi(this.params)
            this.rows=data.rows
            this.total=data.total
        }
    },
    created() {
    }
}
