
const state = {
   payModal:false
};

const mutations = {
    openModal(state) {
        state.payModal = true
    },
    closeModal(state) {
        state.payModal = false
    }
};

const actions = {

};

const getters = {

};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
