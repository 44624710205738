// user.js
import axios from "axios";

const state = {
    goodsId: "",
    goodList: [
        
    ],
    pptGoodsList: [

    ]
};

const mutations = {
    SET_GOODS_LIST(state, goodsList) {
        state.goodList = goodsList;
    },
    SET_PPT_GOODS_LIST(state, goodsList) {
        state.pptGoodsList = goodsList;
    },
};

const actions = {
    setGoodsList({ commit }, goodsList) {
        commit("SET_GOODS_LIST", goodsList);
    },
    setPPTGoodsList({ commit }, goodsList) {
        commit("SET_PPT_GOODS_LIST", goodsList);
    },
};

const getters = {

};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
