<template>
  <div class="app-navbar-item ms-2 ms-lg-6" id="kt_header_user_menu_toggle" @mouseover="handleMouseOver"
       @mouseout="handleMouseOut" style="display: flex;align-items: center;cursor: pointer">
    <div class="cursor-pointer symbol symbol-circle symbol-30px symbol-lg-45px show"
         data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-attach="parent"
         data-kt-menu-placement="bottom-end">
      <i class="ki-outline ki-profile-circle text-gray-700 fs-2tx" style="font-size: 22px!important;"></i>
    </div>
    <div style="margin-left: 4px;font-size: 17px">
      {{ userInfo.userName }}
    </div>
    <div
        class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px"
        :class="{'show':isHovered}"
        data-kt-menu="true" style="z-index: 107; position: fixed; inset: 0 0 auto auto; margin: 0; transform: translate(-78.8889px, 70px);">
      <div class="menu-item px-3">
        <div class="menu-content d-flex align-items-center px-3">
          <div class="symbol symbol-50px me-5">
<!--            <img alt="Logo" :src="userInfo.img"/>-->
            <i class="ki-outline ki-profile-circle text-gray-700 fs-2tx" style="font-size: 26px!important;"></i>
          </div>
          <div class="d-flex flex-column">
            <!--用户名-->
            <div class="fw-bold d-flex align-items-center fs-5">{{ userInfo.userName }}
              <!--职位-->
<!--              <span class="badge badge-light-success fw-bold fs-8 px-2 py-1 ms-2">{{ userInfo.region }}</span>-->
            </div>
            <!--手机号-->
            <a href="javascript:void(0)"
               class="fw-semibold text-muted text-hover-primary fs-7">{{ userInfo.tel }}</a>
          </div>
        </div>
      </div>
      <div class="separator my-2"></div>

      <div class="menu-item px-5">
        <a href="javascript:void(0)" class="menu-link px-5" @click="openEditMyInfo">编辑资料</a>
      </div>
      <div class="menu-item px-5">
        <a href="javascript:void(0)" class="menu-link px-5" @click="dialogVisible=true">修改密码</a>
      </div>
      <div class="menu-item px-5">
        <a href="javascript:void(0)" class="menu-link px-5" @click="loginOutMethod">退出登录</a>
      </div>
    </div>
    <el-dialog
        title="修改密码"
        :visible.sync="dialogVisible"
        width="30%"
        :modal="false"
    >
      <el-form :model="form" :rules="rulesCreate" ref="createFrom" label-width="100px">
        <el-form-item label="旧密码" prop="oldPassword">
          <el-input v-model="form.oldPassword"
                    autocomplete="off"
                    placeholder="请输入新密码"
                    show-password
                    style="width: 300px"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword">
          <el-input v-model="form.newPassword"
                    autocomplete="off"
                    placeholder="请输入新密码"
                    show-password
                    style="width: 300px"></el-input>
        </el-form-item>
        <el-form-item label="重复新密码" prop="secondPassword">
          <el-input v-model="form.secondPassword"
                    autocomplete="off"
                    placeholder="请输入在输入一次密码"
                    show-password
                    style="width: 300px"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeForm">取 消</el-button>
        <el-button type="primary" @click="updatePawd">提 交</el-button>
      </span>
    </el-dialog>

    <el-dialog
        title="编辑资料"
        :visible.sync="editMyInfoDialogVisible"
        width="30%"
        :modal="false"
    >
      <el-form :model="myUserInfo" :rules="myUserInfoFormRules" ref="myUserInfoForm" label-width="150px">

        <el-form-item label="修改头像/修改LOGO" prop="avatarLOGOFile" style="margin-bottom: 20px !important;">
          <br>
          <div class="col-lg-8">
            <!--begin::Image input-->
            <div class="image-input image-input-outline blankPng" data-kt-image-input="true"
                 style="background-image: url(assets/media/avatars/blank.png)">
              <!--begin::Preview existing avatar-->
              <div class="image-input-wrapper w-125px h-125px"
                   :style="`background-image: url(${imageLOGOSrc})`"></div>
              <!--end::Preview existing avatar-->
              <!--begin::Label-->
              <label
                  class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                  data-bs-original-title="上传图像" data-bs-toggle="tooltip" data-kt-image-input-action="change"
                  title=""> <i class="bi bi-pencil-fill fs-7"></i>
                <!--begin::Inputs--> <input id="pic_file_list" ref="avatarLOGOFile"
                                            accept=".png, .jpg, .jpeg" name="avatar" type="file"
                                            @change="uploadPic('avatarLOGOFile')">
                <input name="avatar_remove" type="hidden">
                <input id="picLOGOUrl" name="picLOGOUrl" type="hidden" value="">
              </label>
              <!--end::Label-->
              <!--begin::Cancel-->
              <span
                  class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                  data-bs-original-title="取消" data-bs-toggle="tooltip" data-kt-image-input-action="cancel"
                  title=""> <i class="bi bi-x fs-2"></i>
										</span>
              <!--end::Cancel-->
              <!--begin::Remove-->
              <span
                  class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                  data-bs-original-title="移除图像" data-bs-toggle="tooltip" data-kt-image-input-action="remove"
                  title=""> <i class="bi bi-x fs-2"></i>
										</span>
              <!--end::Remove-->
            </div>
            <!--end::Image input-->
            <!--begin::Hint-->
            <div class="form-text">允许的文件类型: png, jpg, jpeg.
              <br>
              建议尺寸：130*130
            </div>
            <!--end::Hint-->
          </div>
        </el-form-item>

        <el-form-item label="公司名称" prop="companyName" style="margin-bottom: 20px !important;">
          <el-input v-model="myUserInfo.companyName" disabled style="width: 80%"></el-input>
        </el-form-item>

        <el-form-item label="联系人姓名" prop="userName" style="margin-bottom: 20px !important;">
          <el-input v-model="myUserInfo.nickName" style="width: 80%"></el-input>
        </el-form-item>

        <el-form-item label="联系人手机号" prop="tel" style="margin-bottom: 20px !important;">
          <el-input v-model="myUserInfo.tel"
                    style="width: 80%"
                    show-word-limit
                    maxlength="11"
          ></el-input>
        </el-form-item>

        <el-form-item label="验证码" prop="code" v-if="isSendCode" style="margin-bottom: 20px !important;">
          <div style="display: flex">
            <el-input v-model="myUserInfo.code" style="width: 40%;" placeholder="请输入6位验证码"></el-input>
            <el-button type="primary" style="width: 35%; margin-left: 5%" @click="sendCode" plain
                       :disabled="sendCodeStatus || countdown > 0">
              {{ sendCodeStatus ? '发送中...' : countdown > 0 ? `${countdown}秒后重试` : '发送验证码' }}
            </el-button>
          </div>
        </el-form-item>


        <el-form-item label="邮箱" prop="email" style="margin-bottom: 20px !important;">
          <el-input v-model="myUserInfo.email"
                    placeholder="请输入邮箱"
                    style="width: 80%"></el-input>
        </el-form-item>

        <div v-if="myUserInfo.region != '非协会版'">
          <el-form-item label="公司级别" prop="region" style="margin-bottom: 20px !important;">
            <el-input v-model="myUserInfo.region" disabled style="width: 80%"></el-input>
          </el-form-item>

          <el-form-item label="公司详细地址" prop="companyAddress" style="margin-bottom: 20px !important;">
            <el-input v-model="myUserInfo.companyAddress"
                      maxlength="50"
                      placeholder="请输入公司详细地址"
                      show-word-limit
                      style="width: 80%"></el-input>
          </el-form-item>

          <el-form-item label="主营业务" prop="mainBusiness" style="margin-bottom: 20px !important;">
            <el-input
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 4}"
                placeholder="请输入主营业务"
                maxlength="100"
                style="width: 80%"
                show-word-limit
                v-model="myUserInfo.mainBusiness">
            </el-input>
          </el-form-item>
          <el-form-item label="公司简介" prop="companyIntroduction" style="margin-bottom: 20px !important;">
            <el-input
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 4}"
                placeholder="请输入公司简介"
                maxlength="100"
                style="width: 80%"
                show-word-limit
                v-model="myUserInfo.companyIntroduction">
            </el-input>
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editMyInfoDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitMyInfo(myUserInfo.region != '非协会版')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {mapActions, mapState} from "vuex";
import {changePasswordApi, getMyInfoApi, updateMyInfoApi} from "@/api/userApi";
import {getCodeApi} from "@/api/vipUserApi";

export default {
  computed: {
    ...mapState("user", ["userInfo"]),
    ...mapState("web", ["obj"])
  },
  data() {
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
        // password 是表单上绑定的字段
      } else if (value !== this.form.newPassword) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    var validateEmail = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入邮箱地址'));
      } else if (!/^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/.test(value)) {
        callback(new Error('邮箱地址格式不正确!'));
      } else {
        callback();
      }
    }
    var validateTel = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入手机号'));
      } else if (!/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(value)) {
        callback(new Error('手机号格式不正确!'));
      } else {
        if (value != this.myUserInfoTemp.tel) {
          this.isSendCode = true;
        } else {
          this.isSendCode = false;
        }
        callback();
      }
    };
    return {
      form: {
        oldPassword: "",
        newPassword: "",
        secondPassword: "",
      },
      rulesCreate: {
        oldPassword: [
          {required: true, message: "请输入旧密码", trigger: "blur"},
          // {min: 6, max: 16, message: "长度在 6 到 16 个字符", trigger: "blur"},
        ],
        newPassword: [
          {required: true, message: "请再次输入新密码", trigger: "blur"},
          {min: 6, max: 16, message: "长度在 6 到 16 个字符", trigger: "blur"}
        ],
        secondPassword: [
          {required: true, validator: validatePass2, trigger: 'change'},
        ],
      },
      myUserInfoFormRules: {
        code: [
          {required: true, message: "请输入验证码", trigger: "blur"}
        ],
        companyName: [
          {required: true, message: "请输入公司名称", trigger: "blur"}
        ],
        tel: [
          {required: true, validator: validateTel, trigger: "blur"}
        ],
        userName: [
          {required: true, message: "请输入联系人姓名", trigger: "blur"}
        ],
        email: [
          {required: true, validator: validateEmail, trigger: "blur"}
        ],
        backup: [
          {required: true, message: "请输入公司名称", trigger: "blur"}
        ],
        companyAddress: [
          {required: true, message: "请输入公司详细地址", trigger: "blur"}
        ],
        region: [
          {required: true, message: "请选择公司级别", trigger: "blur"}
        ],
        mainBusiness: [
          {required: true, message: "请输入公司主营业务", trigger: "blur"}
        ],
        companyIntroduction: [
          {required: true, message: "请输入公司简介", trigger: "blur"}
        ],
      },
      dialogVisible: false,
      myUserInfo: {
        companyName: "",
        img: null,
        nickName: "",
        tel: "",
        userName: "",
        code: "",
      },
      myUserInfoTemp: {},
      editMyInfoDialogVisible: false,
      imageLOGOSrc: "",
      isSendCode: false,
      sendCodeStatus: false,
      sendCodeText: "获取验证码",
      countdown: 0,
      isHovered: false
    };
  },
  methods: {
    handleMouseOver() {
      this.isHovered = true;
    },
    handleMouseOut() {
      this.isHovered = false;
    },
    closeForm() {
      this.dialogVisible = false
      this.form = {
        oldPassword: "",
        newPassword: "",
        secondPassword: "",
      }
    },
    updatePawd() {
      this.$refs.createFrom.validate(async (valid) => {
        if (valid) {
          let {data} = await changePasswordApi(this.form)
          this.$message.success("修改成功")
          this.closeForm()
          this.$router.push('login')
        }
        return false;
      });
    },
    ...mapActions("user", ["loginOut"]),
    loginOutMethod() {
      this.loginOut("1").then(() => {
        this.$router.push(`/login?redirect=${this.$route.fullPath}`)
      });
    },
    test() {
      this.$swal.fire({
        icon: "warning",
        title: "即将开放！",
        toast: true,
        position: "center",
        showConfirmButton: false,
        timer: 2000,
      });
    },
    async getMyInfo() {
      let {data} = await getMyInfoApi();
      this.myUserInfo = data
      this.myUserInfoTemp = JSON.parse(JSON.stringify(data))
    },
    /**
     * 上传图片
     *
     * @param type 表单中具体的上传框
     */
    uploadPic(type) {
      const fileInput = this.$refs[type];
      if (fileInput.files.length > 0) {
        const selectedFile = fileInput.files[0];
        // 使用FileReader读取上传的文件
        const reader = new FileReader();

        reader.onload = () => {
          // 将文件转换为Base64格式
          if (type == "avatarFile") {
            this.imageSrc = reader.result;
          } else {
            this.imageLOGOSrc = reader.result;
          }
        };

        reader.readAsDataURL(selectedFile);
      }
    },
    openEditMyInfo() {
      this.imageLOGOSrc = this.myUserInfo.img
      this.editMyInfoDialogVisible = true
    },
    submitMyInfo(isVip) {
      this.$refs["myUserInfoForm"].validate(async (valid) => {
        if (!valid) {
          return false;
        }
        let formData = new FormData();
        if (this.$refs.avatarLOGOFile.files[0]) {
          formData.append("file", this.$refs.avatarLOGOFile.files[0]);
          formData.append("isModifyImg", true);
        }
        formData.append("tel", this.myUserInfo.tel);
        formData.append("email", this.myUserInfo.email);
        formData.append("staffName", this.myUserInfo.nickName);
        formData.append("isVip", isVip);
        if(this.myUserInfo.code){
          formData.append("code", this.myUserInfo.code);
        }
        if (isVip) {
          formData.append("mainBusiness", this.myUserInfo.mainBusiness);
          formData.append("companyAddress", this.myUserInfo.companyAddress);
          formData.append("companyIntroduction", this.myUserInfo.companyIntroduction);
        }

        let data = await updateMyInfoApi(formData)

        if (data.code != 200) {
          return ;
        }

        this.editMyInfoDialogVisible = false
        this.getMyInfo()
        this.$message.success("修改成功")
      });
    },
    sendCode() {
      if (this.countdown > 0 || this.sendCodeStatus) {
        return; // 防止重复点击发送
      }
      if (this.myUserInfo.tel === "") {
        this.$message({
          message: '请输入手机号',
          type: 'warning'
        });
        return;
      }
      //验证手机号正则
      if (!this.myUserInfo.tel.match(/^1[34578]\d{9}$/)) {
        this.$message({
          message: '手机号格式不正确',
          type: 'warning'
        });
        return;
      }

      this.sendCodeStatus = true;
      this.startCountdown();
      this.sendGetCode();

    },
    async sendGetCode() {

      let pram = {
        tel: this.myUserInfo.tel,
        type: this.obj.tplId
      }
      await getCodeApi(pram)
      this.sendCodeStatus = false;
    },
    startCountdown() {
      this.countdown = 60;
      const timer = setInterval(() => {
        this.countdown--;
        if (this.countdown <= 0) {
          clearInterval(timer);
        }
      }, 1000);

    },
  },

  mounted() {
    this.getMyInfo();
  }
}
</script>
<style>
.myImg {
  background: none !important;
}
</style>
